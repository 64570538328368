import React, { useState } from "react"

export const FoodMatrixContext = React.createContext()

const FoodMatrixContextProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState("All")
  const [selectedNutrientA, setSelectedNutrientA] = useState("proteinLevel")
  const [selectedNutrientB, setSelectedNutrientB] = useState("pLevel")
  const [activeFilters, setActiveFilters] = useState({})

  return (
    <FoodMatrixContext.Provider
      value={{
        selectedCategory: selectedCategory,
        setSelectedCategory: setSelectedCategory,
        selectedNutrientA: selectedNutrientA,
        setSelectedNutrientA: setSelectedNutrientA,
        selectedNutrientB: selectedNutrientB,
        setSelectedNutrientB: setSelectedNutrientB,
        activeFilters: activeFilters,
        setActiveFilters: setActiveFilters,
      }}
    >
      {children}
    </FoodMatrixContext.Provider>
  )
}

export default FoodMatrixContextProvider
