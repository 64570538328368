import React, { useState } from "react"

export const FoodCheckContext = React.createContext()

const FoodCheckContextProvider = ({ children }) => {
  const [selectedNutrients, setSelectedNutrients] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState("All")
  const [searchText, setSearchText] = useState("")
  const [detailItem, setDetailItem] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [orderAsc, setOrderAsc] = useState(true)

  return (
    <FoodCheckContext.Provider
      value={{
        selectedNutrients: selectedNutrients,
        setSelectedNutrients: setSelectedNutrients,
        selectedCategory: selectedCategory,
        setSelectedCategory: setSelectedCategory,
        searchText: searchText,
        setSearchText: setSearchText,
        detailItem: detailItem,
        setDetailItem: setDetailItem,
        orderBy: orderBy,
        setOrderBy: setOrderBy,
        orderAsc: orderAsc,
        setOrderAsc: setOrderAsc,
      }}
    >
      {children}
    </FoodCheckContext.Provider>
  )
}

export default FoodCheckContextProvider
