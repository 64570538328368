export const units = {
  weight: ["kg", "lb"],
  height: ["cm", "ft"],
  hba1c: ["%", "mmol/mol"],
  hb: ["g/dL", "mmol/L"],
  albumin: ["mg/L", "mg/dL"],
  crp: ["mg/dL", "mg/L"],
  proteinU: ["g/L", "mg/L"],
  gfr: ["ml/min/1,73m²"],
  ldl: ["mg/dL", "mmol/L"],
  triglycerides: ["mg/dL", "mmol/L"],
  p: ["mmol/L", "mEq/L", "mg/dL"],
  ca: ["mmol/L", "mEq/L", "mg/dL"],
  pth: ["pg/mL", "ng/L"],
  k: ["mmol/L", "mEq/L"],
  na: ["mmol/L", "mEq/L"],
  uricAcid: ["mg/dL", "mmol/L"],
  be: ["mmol/L", "mEq/L"],
  bun: ["mg/dL", "mmol/L"],
}
