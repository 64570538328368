// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-health-advisor-js": () => import("./../../../src/pages/healthAdvisor.js" /* webpackChunkName: "component---src-pages-health-advisor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-js": () => import("./../../../src/pages/knowledge.js" /* webpackChunkName: "component---src-pages-knowledge-js" */),
  "component---src-pages-nutrients-js": () => import("./../../../src/pages/nutrients.js" /* webpackChunkName: "component---src-pages-nutrients-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

