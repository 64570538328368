import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const stagesQuery = graphql`
  {
    allStrapiStage {
      nodes {
        stageCKD
        descriptionCKD
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export const AppContext = React.createContext()

const AppContextProvider = ({ children }) => {
  const [conditionsAccept, setConditionsAccept] = useState(false)
  const [CKDStage, setCKDStage] = useState(null)
  const [aboutTabIndex, setAboutTabIndex] = useState(0)

  const {
    allStrapiStage: { nodes: stages },
  } = useStaticQuery(stagesQuery)

  return (
    <AppContext.Provider
      value={{
        conditionsAccept: conditionsAccept,
        setConditionsAccept: setConditionsAccept,
        CKDStage: CKDStage,
        setCKDStage: setCKDStage,
        allStages: stages,
        aboutTabIndex: aboutTabIndex,
        setAboutTabIndex: setAboutTabIndex,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
