import React from "react"
import ScreenContextProvider from "./screenContext"
import FoodCheckContextProvider from "./foodCheckContext"
import FoodMatrixContextProvider from "./foodMatrixContext"
import LabContextProvider from "./labContext"
import AppContextProvider from "./appContext"
import HealthCheckContextProvider from "./healthCheckContext"
import CustomThemeProvider from "./themeContext"

const providers = [
  AppContextProvider,
  CustomThemeProvider,
  ScreenContextProvider,
  FoodCheckContextProvider,
  FoodMatrixContextProvider,
  HealthCheckContextProvider,
  LabContextProvider,
]

const AllProviders = ({ providers, children }) => {
  return providers.reduce((Wrapped, Provider) => {
    return <Provider>{Wrapped}</Provider>
  }, children)
}

const ContextProvider = ({ children }) => {
  return <AllProviders providers={providers} children={children} />
}

export default ContextProvider
