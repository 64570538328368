import React, { useState } from "react"
import { units } from "../components/tools/tool/labTools/healthCheckSteps/units"

export const HealthCheckContext = React.createContext()

const HealthCheckContextProvider = ({ children }) => {
  const [gender, setGender] = useState("female")
  const [age, setAge] = useState(null)
  const [height, setHeight] = useState(null)
  const [heightUnit, setHeightUnit] = useState(units.height[0])
  const [weight, setWeight] = useState(null)
  const [weightUnit, setWeightUnit] = useState(units.weight[0])
  const [smoking, setSmoking] = useState(0)
  const [exerciseLevel, setExerciseLevel] = useState(3)
  const [diabetes, setDiabetes] = useState(false)
  const [hba1c, setHba1c] = useState(null)
  const [hba1cUnit, setHba1cUnit] = useState(units.hba1c[0])
  const [cvd, setCvd] = useState(false)
  const [hb, setHb] = useState(null)
  const [hbUnit, setHbUnit] = useState(units.hb[0])
  const [albumin, setAlbumin] = useState(null)
  const [albuminUnit, setAlbuminUnit] = useState(units.albumin[0])
  const [crp, setCrp] = useState(null)
  const [crpUnit, setCrpUnit] = useState(units.crp[0])
  const [proteinU, setProteinU] = useState(null)
  const [proteinUUnit, setProteinUUnit] = useState(units.proteinU[0])
  const [gfr, setGfr] = useState(null)
  const [gfr1y, setGfr1y] = useState(null)
  const [bun, setBun] = useState(null)
  const [bunUnit, setBunUnit] = useState(units.bun[0])
  const [bpSys, setBpSys] = useState(null)
  const [bpDia, setBpDia] = useState(null)
  const [ldl, setLdl] = useState(null)
  const [ldlUnit, setLdlUnit] = useState(units.ldl[0])
  const [trig, setTrig] = useState(null)
  const [trigUnit, setTrigUnit] = useState(units.triglycerides[0])
  const [bpMed, setBpMed] = useState(false)
  const [k, setK] = useState(null)
  const [kUnit, setKUnit] = useState(units.k[0])
  const [p, setP] = useState(null)
  const [pUnit, setPUnit] = useState(units.p[0])
  const [na, setNa] = useState(null)
  const [naUnit, setNaUnit] = useState(units.na[0])
  const [ca, setCa] = useState(null)
  const [caUnit, setCaUnit] = useState(units.ca[0])
  const [pth, setPth] = useState(null)
  const [pthUnit, setPthUnit] = useState(units.pth[0])
  const [weightGain, setWeightGain] = useState(2)
  const [cramps, setCramps] = useState(false)
  const [ktv, setKtv] = useState(null)
  const [be, setBe] = useState(null)
  const [beUnit, setBeUnit] = useState(units.be[0])
  const [gout, setGout] = useState(false)
  const [nausea, setNausea] = useState(false)
  const [fatigue, setFatigue] = useState(false)
  const [itching, setItching] = useState(false)
  const [breathShortness, setBreathShortness] = useState(false)
  const [edema, setEdema] = useState(false)
  const [medTransSkipDays, setMedTransSkipDays] = useState(null)
  const [nsaid, setNsaid] = useState(false)
  const [esa, setEsa] = useState(false)
  const [diaLen, setDiaLen] = useState(4)

  const [status, setStatus] = useState("start")
  const [step, setStep] = useState(1)

  return (
    <HealthCheckContext.Provider
      value={{
        age: age,
        setAge: setAge,
        gender: gender,
        setGender: setGender,
        height: height,
        setHeight: setHeight,
        heightUnit: heightUnit,
        setHeightUnit: setHeightUnit,
        weight: weight,
        setWeight: setWeight,
        weightUnit: weightUnit,
        setWeightUnit: setWeightUnit,
        smoking: smoking,
        setSmoking: setSmoking,
        exerciseLevel: exerciseLevel,
        setExerciseLevel: setExerciseLevel,
        diabetes: diabetes,
        setDiabetes: setDiabetes,
        hba1c: hba1c,
        setHba1c: setHba1c,
        hba1cUnit: hba1cUnit,
        setHba1cUnit: setHba1cUnit,
        cvd: cvd,
        setCvd: setCvd,
        hb: hb,
        setHb: setHb,
        hbUnit: hbUnit,
        setHbUnit: setHbUnit,
        albumin: albumin,
        setAlbumin: setAlbumin,
        albuminUnit: albuminUnit,
        setAlbuminUnit: setAlbuminUnit,
        crp: crp,
        setCrp: setCrp,
        crpUnit: crpUnit,
        setCrpUnit: setCrpUnit,
        proteinU: proteinU,
        setProteinU: setProteinU,
        proteinUUnit: proteinUUnit,
        setProteinUUnit: setProteinUUnit,
        gfr: gfr,
        setGfr: setGfr,
        gfr1y: gfr1y,
        setGfr1y: setGfr1y,
        bun: bun,
        setBun: setBun,
        bunUnit: bunUnit,
        setBunUnit: setBunUnit,
        bpSys: bpSys,
        setBpSys: setBpSys,
        bpDia: bpDia,
        setBpDia: setBpDia,
        ldl: ldl,
        setLdl: setLdl,
        ldlUnit: ldlUnit,
        setLdlUnit: setLdlUnit,
        trig: trig,
        setTrig: setTrig,
        trigUnit: trigUnit,
        setTrigUnit: setTrigUnit,
        bpMed: bpMed,
        setBpMed: setBpMed,
        k: k,
        setK: setK,
        kUnit: kUnit,
        setKUnit: setKUnit,
        p: p,
        setP: setP,
        pUnit: pUnit,
        setPUnit: setPUnit,
        na: na,
        setNa: setNa,
        naUnit: naUnit,
        setNaUnit: setNaUnit,
        ca: ca,
        setCa: setCa,
        caUnit: caUnit,
        setCaUnit: setCaUnit,
        pth: pth,
        setPth: setPth,
        pthUnit: pthUnit,
        setPthUnit: setPthUnit,
        weightGain: weightGain,
        setWeightGain: setWeightGain,
        cramps: cramps,
        setCramps: setCramps,
        ktv: ktv,
        setKtv: setKtv,
        be: be,
        setBe: setBe,
        beUnit: beUnit,
        setBeUnit: setBeUnit,
        gout: gout,
        setGout: setGout,
        nausea: nausea,
        setNausea: setNausea,
        fatigue: fatigue,
        setFatigue: setFatigue,
        itching: itching,
        setItching: setItching,
        breathShortness: breathShortness,
        setBreathShortness: setBreathShortness,
        edema: edema,
        setEdema: setEdema,
        medTransSkipDays: medTransSkipDays,
        setMedTransSkipDays: setMedTransSkipDays,
        nsaid: nsaid,
        setNsaid: setNsaid,
        status: status,
        setStatus: setStatus,
        step: step,
        setStep: setStep,
        esa: esa,
        setEsa: setEsa,
        diaLen: diaLen,
        setDiaLen: setDiaLen,
      }}
    >
      {children}
    </HealthCheckContext.Provider>
  )
}

export default HealthCheckContextProvider
