import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      lighter: "#64B5F6",
      main: "#5A89E5",
      darker: "#408DDB",
    },
    neutral: {
      darker: "rgb(130, 130, 130)",
      main: "rgb(170, 170, 170)",
      lighter: "rgb(245,245,245)",
    },
  },
})

const CustomThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default CustomThemeProvider
